import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";

import LoadingOverlay from "react-loading-overlay";

import axios from "axios";
import SingleSelect from "components/SingleSelect";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import Danger from "components/Typography/Danger";
import CardFooter from "components/Card/CardFooter";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import Chip from "@mui/material/Chip";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function DailyReport() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const UserDetails = JSON.parse(window.localStorage.getItem("userdetails"));
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [Raised, setRaised] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [Clear, setClear] = useState(false);
  const [data, setData] = useState({
    ProjectId: "",
    Project: "",
    Hours: "",
  });
  const [totalEnteredHours, setTotalEnteredHours] = useState(0);
  const [HystoryData, SetHystoryData] = useState([]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);
    formData.append("StartDate", "2022-09-01");
    formData.append("EndDate", "2022-09-30");

    axios({
      method: "post",
      url: Domain + "/GetDailyReportHystory.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Hystory:", response);
        if (response.data.success) {
          SetHystoryData(response.data.data);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [saved]);
  console.log("HystoryData::::", HystoryData);
  console.log("startDate:::::", startDate);

  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  useEffect(() => {}, []);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  const [TotalHoursError, setTotalHoursError] = useState(false);
  function HandleSave() {
    // console.log("Array:", array);
    if (array != "") {
      // console.log("totalEnteredHours", totalEnteredHours);
      if (totalEnteredHours == totalHours) {
        array.map((d) => {
          let formData = new FormData();
          formData.append("DeveloperId", UserDetails.id);
          if (startDate != "")
            formData.append(
              "StartDate",
              new Date(startDate).toISOString().slice(0, 19).replace("T", " ")
            );
          formData.append("ProjectName", d.Project);
          formData.append("ProjectId", d.ProjectId);
          formData.append("Hours", d.Hours);
          axios({
            method: "post",
            url: Domain + "/SaveDailyReport.php",
            data: formData,
            config: { headers: { "Content-Type": "multipart/form-data" } },
          })
            .then(function (response) {
              //handle success
              console.log("Saved:", response);
              if (response.data.success) {
                setStartDate("");
                setClear(true);
                setArray([]);
                showSavedNotification();
                setTotalEnteredHours(0);
              } else if (response.data.message == "completed") {
                alert("Already inserted 6 hours!");
              } else if (response.data.message == "incomplete") {
                alert("Inserted " + response.data.Hours + " Hours");
              }
            })
            .catch(function (response) {
              //handle error
              showDeletedNotification();
              console.log(response);
            });
        });
      } else {
        setTotalHoursError(true);
      }
    }
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);
    axios({
      method: "post",
      url: Domain + "/GetDailyProject.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        // console.log("Raised Response:", response);
        if (response.data.success) {
          if (response.data.TotalCount != 0) {
            setRaised(response.data.data);
            setLoading(false);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, []);

  //Maping Data to tables
  const ProjectList = Raised.map((d) => ({
    value: d.id,
    label: d.ProjectName,
  }));
  const [Project, setProject] = React.useState({
    Id: null,
    label: "",
  });
  // =====================================
  // =======================================
  const [validated, setValdated] = useState(true);
  const [array, setArray] = useState([]);
  useEffect(() => {
    setClear(false);
    setData({
      ...data,
      Project: Project.Label,
      ProjectId: Project.Id,
    });
  }, [Project.Id]);
  function HandleAdd() {
    if (data.Hours != "" && Project.Id != null) {
      setTotalEnteredHours(parseInt(totalEnteredHours) + parseInt(data.Hours));
      setValdated(true);
      setArray((array) => [...array, data]);
      setData({
        ProjectId: "",
        Project: "",
        Hours: "",
      });
    } else {
      setValdated(false);
    }
  }
  useEffect(() => {
    // console.log("Data", array);
  }, [array]);
  function HandleClear() {
    setArray([]);
    setTotalHoursError(false);
    setTotalEnteredHours(0);
    setClear(true);
  }
  const [totalHours, setTotalHours] = useState(6);
  function HandleChecked() {
    if (totalHours == 6) setTotalHours(3);
    else setTotalHours(6);
  }
  return (
    <>
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="Saved Successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error Please try again!"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <NavPills
            active={0}
            color="info"
            tabs={[
              {
                tabButton: "Log Entry",
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card style={{ minHeight: "400px" }}>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              Submit daily work log
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={Loading}
                              spinner
                              text="Please Wait.."
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      label="Date ( Optional )"
                                      value={startDate}
                                      onChange={(newValue) => {
                                        setStartDate(
                                          newValue.toLocaleDateString("en-CA")
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox onChange={HandleChecked} />
                                    }
                                    label="Half Day"
                                  />{" "}
                                  <b>Total Hours is {totalHours}</b>{" "}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                  <SingleSelect
                                    clear={Clear}
                                    noOptionsMessage="No Projects available"
                                    placeholder="Select Project"
                                    Options={ProjectList}
                                    setValue={setProject}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={5}>
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="standard"
                                  >
                                    <TextField
                                      style={{ marginTop: "23px" }}
                                      size="small"
                                      type="number"
                                      variant="outlined"
                                      onChange={(e) => HandleData(e)}
                                      value={data.Hours}
                                      label="Total Hours"
                                      id="Hours"
                                    />
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                  <Button
                                    style={{ marginTop: "22px" }}
                                    color="info"
                                    onClick={HandleAdd}
                                  >
                                    Add
                                  </Button>
                                </GridItem>
                                {validated ? (
                                  <></>
                                ) : (
                                  <Danger>Input all the details</Danger>
                                )}
                              </GridContainer>
                              <Table
                                tableHeaderColor="info"
                                tableHead={[
                                  "Project Id",
                                  "Project Name",
                                  "Total Hours",
                                ]}
                                tableData={array}
                                noAction={true}
                                Images={false}
                                loading={false}
                              />
                            </LoadingOverlay>
                          </CardBody>
                          <CardFooter>
                            <Button color="danger" onClick={HandleClear}>
                              Clear
                            </Button>
                            <Button color="success" onClick={HandleSave}>
                              Save Changes
                            </Button>
                          </CardFooter>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                              {TotalHoursError ? (
                                <>
                                  <Danger>
                                    Total Hours must be <b>{totalHours}</b>
                                  </Danger>
                                  <br />
                                </>
                              ) : (
                                <></>
                              )}
                            </GridItem>
                          </GridContainer>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "History",
                tabContent: (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              Daily work log missing dates
                            </h4>
                          </CardHeader>
                          <CardBody>
                            {HystoryData.map((item) => (
                              <>
                                <Button onClick={() => setStartDate(item.date)}>
                                  {" "}
                                  {item.date}{" "}
                                </Button>
                                <br />
                              </>
                            ))}
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

import Danger from "components/Typography/Danger";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DataTable from "components/Table/DataTable";
import LinearIndeterminate from "components/LinearLoading/LinearLoading";
import NoData from "assets/img/noData.jpg";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function ProjectReport() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [edit, setEdit] = useState([]);
  const [Developers, setDevelopers] = useState([]);
  const [validated, setValidated] = useState(true);
  const [loading, setLoading] = useState(true);
  const [AddStaff, setAddStaff] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [type, ChangeType] = useState("1");

  // const [AssignView, setAssignView] = useState(false);
  // const [ViewStaff, setViewStaff] = useState(false);

  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Form Data

  //Function for Validating fields

  function ValidateFields() {
    if (startDate == "") {
      return false;
    } else if (endDate == "") {
      return false;
    } else return true;
  }
  //To get Developer list from database

  function HandleSave() {
    if (ValidateFields()) {
      setValidated(true);
      let formData = new FormData();
      formData.append("Type", type);

      formData.append(
        "StartDate",
        new Date(startDate).toISOString().slice(0, 19).replace("T", " ")
      );
      formData.append(
        "EndDate",
        new Date(endDate).toISOString().slice(0, 19).replace("T", " ")
      );
      axios({
        method: "post",
        url: Domain + "/GetProjectReportByDate.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.data.success) {
            setDevelopers(response.data.data);
            console.log("setDevelopers ", response.data);
            setLoading(false);
            showSavedNotification();
            setAddStaff(false);
          } else {
            showDeletedNotification();
            console.log("failed1");
            setDevelopers([]);
            setAddStaff(false);
          }
        })
        .catch(function (response) {
          //handle error
          setDeleted(true);
          console.log(response);
        });
    } else {
      setValidated(false);
    }
  }
  const StaffData = Developers.map((d, key) => ({
    id: key + 1,
    Project: d.Project,
    TotalTickets: d.TotalTickets ? d.TotalTickets : 0,
    TotalHours: d.TotalHours,
    Assigned: d.Assigned ? d.Assigned : 0,
    Working: d.Working ? d.Working : 0,
    Completed: d.Completed ? d.Completed : 0,
    Verified: d.Verified ? d.Verified : 0,
    Pending: d.Pending ? d.Pending : 0,
  }));
  // console.log("Developers Data", events);
  //Function to save Data

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName]
          ? isNaN(row[fieldName])
            ? row[fieldName]
            : parseInt(row[fieldName])
          : 0;
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    const report = StaffData.map((d) => ({
      id: d.id,
      Project: d.Project,
      TotalTickets: d.TotalTickets,
      TotalHours: d.TotalHours,
      Assigned: d.Assigned,
      Working: d.Working,
      Completed: d.Completed,
      Verified: d.Verified,
      Pending: d.Pending,
    }));
    // var data1 = [
    //   { name: "John", city: "Seattle" },
    //   { name: "Mike", city: "Los Angeles" },
    //   { name: "Zach", city: "New York" },
    // ];
    let header = [
      "SL.No",
      "Project Name",
      "Total Tickets",
      "Total Hours",
      "Assigned",
      "Working",
      "Completed",
      "Verified",
      "Pending",
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(report, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "18bdd1");
      range.style("border", false);
      return workbook.outputAsync().then((res) => {
        if (startDate == "" || endDate == "") {
          saveAs(res, "Complete-project-report.xlsx");
        } else {
          saveAs(
            res,
            "Project-report " + startDate + " to " + endDate + ".xlsx"
          );
        }
      });
    });
  }

  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", type);

    axios({
      method: "post",
      url: Domain + "/GetProjectReport.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.success) {
          setDevelopers(response.data.data);
          console.log("setDevelopers ", response.data.data);
          setLoading(false);
        } else {
          showDeletedNotification;
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        setDeleted(true);
        console.log(response);
      });
  }, [type]);
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Report Generated Successfully!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message="Error! Try again."
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={type}
              >
                <FormControlLabel
                  onChange={() => ChangeType("1")}
                  value="1"
                  control={<Radio />}
                  label="Development"
                />
                <FormControlLabel
                  onChange={() => ChangeType("2")}
                  value="2"
                  control={<Radio />}
                  label="Maintenance"
                />
              </RadioGroup>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {AddStaff ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <form>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>
                    Select Report Dates
                  </h4>
                </CardHeader>

                <CardBody>
                  <Card className={classes.cardStyle}>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="From Date"
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(
                                newValue.toLocaleDateString("en-CA")
                              );
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="To Date"
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue.toLocaleDateString("en-CA"));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </GridItem>
                    </GridContainer>
                  </Card>

                  <GridContainer>
                    <GridItem xs={12} sm={5} md={4}>
                      {" "}
                      {validated ? (
                        <></>
                      ) : (
                        <Danger>Select Start and End dates</Danger>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button onClick={() => setAddStaff(false)} color="danger">
                    Cancel
                  </Button>
                  {/* <Button onClick={HandleBulkInsert} color="info">
                    Save Bulk
                  </Button> */}
                  <Button onClick={HandleSave} color="info">
                    Search
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <Button onClick={() => setAddStaff(true)} color="info">
            Generate Monthly Report
          </Button>
        </>
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <h4 className={classes.cardTitleWhite}>Project Report</h4>
                  <p className={classes.cardCategoryWhite}>
                    Complete Report Based on Projects shown below
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {Developers != "" ? (
                    <Button onClick={saveAsExcel} color="success">
                      Download Excel
                    </Button>
                  ) : (
                    <></>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {loading ? (
                <LinearIndeterminate />
              ) : (
                <>
                  {Developers != "" ? (
                    <>
                      <DataTable
                        columns={[
                          {
                            field: "id",
                            headerName: "SL.No",
                            width: 80,
                          },
                          {
                            field: "Project",
                            headerName: "Project Name",
                            width: 220,
                          },
                          {
                            field: "TotalTickets",
                            headerName: "Total Tickets",
                            width: 120,
                          },
                          {
                            field: "TotalHours",
                            headerName: "Total Hours",
                            width: 120,
                          },
                          {
                            field: "Assigned",
                            headerName: "Assigned",
                            width: 120,
                          },
                          {
                            field: "Working",
                            headerName: "Working",
                            width: 120,
                          },
                          {
                            field: "Verified",
                            headerName: "Verified",
                            width: 120,
                          },
                          {
                            field: "Completed",
                            headerName: "Completed",
                            width: 120,
                          },
                          {
                            field: "Pending",
                            headerName: "Pending",
                            width: 120,
                          },
                        ]}
                        rows={StaffData}
                      />
                    </>
                  ) : (
                    <center>
                      <img
                        style={{ maxHeight: "350px" }}
                        src={NoData}
                        alt="No report found for the selected Dates"
                      />
                    </center>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

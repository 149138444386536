import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Container, TextField } from "@material-ui/core";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";

// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";
import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";
import CustomTable from "components/Table/RenewalDateTable";

// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function RenewalDates() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [files, setFiles] = useState(null);
  const [validated, setValidated] = React.useState(true);
  // const [Developers, setDevelopers] = React.useState([]);
  // const [userDetails, SetUserDetails] = React.useState([]);
  const nowDate = new Date().toLocaleDateString("en-CA");
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 9);
  const cur_date = currentDate.toLocaleDateString("en-CA");
  console.log(":::", currentDate.toLocaleDateString("en-CA"));
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  // useEffect(() => {
  //   SetUserDetails(JSON.parse(window.localStorage.getItem("userdetails")));
  // }, []);
  console.log("startDate::", startDate);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };

  //Form Data
  const [data, setData] = React.useState({
    Id: 0,
    Name: "",
    Project: "",
    Description: "",
  });
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  const [clear, setClear] = useState(false);
  function HandleClear() {
    setData({
      Id: 0,
      Project: "",
      Name: "",
      Description: "",
    });
    if (clear) setClear(false);
    else setClear(true);
  }
  //Function for Validating fields
  // function ValidateFields() {
  //   if (data.FullName == "") {
  //     return false;
  //   } else if (DeveloperValues.Id == null) {
  //     return false;
  //   } else if (data.Subject == "") {
  //     return false;
  //   } else if (data.Description == "") {
  //     return false;
  //   } else return true;
  // }
  //function to upload
  // function UploadImage() {
  //   if (files != null) {
  //     let formData = new FormData();
  //     formData.append("Image", files[0]);
  //     // console.log(formData);
  //     axios({
  //       method: "post",
  //       url: Domain + "/upload_file.php",
  //       data: formData,
  //       config: { headers: { "Content-Type": "multipart/form-data" } },
  //     })
  //       .then(function (response) {
  //         //handle success
  //         console.log("File Upload Response:", response.data);

  //         data.Image = response.data;
  //         HandleSave();
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log(response);
  //         showDeletedNotification();
  //       });
  //   } else {
  //     HandleSave();
  //   }
  // }

  function HandleSave() {
    console.log(
      // DeveloperValues.Label,
      "TypeListValues.Id::",
      TypeListValues.Id
    );
    if (TypeListValues.Id != null) {
      setValidated(true);
      let formData = new FormData();
      formData.append("Id", data.Id);
      formData.append("Name", data.Name);
      formData.append("Project", data.Project);
      formData.append("Description", data.Description);
      formData.append("RenewalDate", startDate);
      formData.append("TypeOfService", TypeListValues.Label);
      axios({
        method: "post",
        url: Domain + "/saveRenewalDates.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.data.success) {
            HandleClear();
            console.log("saved");
            if (refresh) setRefresh(false);
            else setRefresh(true);
            showSavedNotification();
          } else {
            console.log("failed");
            showDeletedNotification();
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification();
        });
    } else {
      setValidated(false);
    }
  }
  const [Working, setWorking] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let formData = new FormData();
    axios({
      method: "post",
      url: Domain + "/GetAllRenewalData.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("RenewalDates Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setWorking(response.data.data);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [refresh]);
  const [edit, setEdit] = useState("");
  console.log("edit::", edit);
  const WorkingData = Working.map((d) => ({
    Id: d.Id,
    Type: d.Type,
    Name: d.Name,
    Project: d.Project,
    RenewalDate: d.RenewalDate,
    Description: d.Description.slice(0, 50) + "...",
    bgColor:
      d.RenewalDate <= nowDate
        ? "#ff0000"
        : d.RenewalDate <= cur_date
        ? "#f7a497"
        : "#ffffff",
  }));
  const TypeList = [
    {
      value: "1",
      label: "Hosting",
    },
    {
      value: "2",
      label: "Domain",
    },
  ];
  // const [DeveloperValues, setDevelopersValue] = React.useState({
  //   Id: null,
  //   label: "",
  // });
  const [TypeListValues, setTypeListValue] = React.useState({
    Id: null,
    label: "",
  });
  console.log("TypeListValues:", TypeListValues);
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (edit != "") {
      let formData = new FormData();
      formData.append("Id", edit);
      axios({
        method: "post",
        url: Domain + "/GetAllRenewalDataById.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("GetAllRenewalDataById Response:", response.data);
          if (response.data.TotalCount != 0) {
            if (response.data.success) {
              setData(response.data.data[0]);
              setStartDate(response.data.data[0].RenewalDate);
              if (response.data.data[0].Type === "Domain") {
                setSelected({
                  value: "2",
                  label: "Domain",
                });
                setTypeListValue({
                  Id: "2",
                  Label: "Domain",
                });
              } else if (response.data.data[0].Type === "Hosting") {
                setSelected({
                  value: "1",
                  label: "Hosting",
                });
                setTypeListValue({
                  Id: "1",
                  Label: "Hosting",
                });
              }
            }
          }
        })
        .catch(function (response) {
          //handle error
          showDeletedNotification();
          console.log(response);
        });
    }
  }, [edit]);

  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Success!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error! Try again"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Add new Renewal</h4>
              <p className={classes.cardCategoryWhite}>
                Fill up the details and click Save
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={11}>
                  <SingleSelect
                    selectedVal={selected}
                    clear={clear}
                    noOptionsMessage="No type"
                    placeholder="Select Type"
                    Options={TypeList}
                    setValue={setTypeListValue}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.Name}
                    labelText="Name"
                    id="Name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    onChange={(e) => HandleData(e)}
                    value={data.Project}
                    labelText="Project"
                    id="Project"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} style={{ marginTop: 27 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Renewal Date"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue.toLocaleDateString("en-CA"));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomFileInput
                    setFiles={setFiles}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      placeholder: "",
                    }}
                    endButton={{
                      buttonProps: {
                        round: true,
                        color: "info",
                        justIcon: true,
                      },
                      icon: <AttachFile />,
                    }}
                  />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={11}>
                  <CustomInput
                    type="Description"
                    onChange={(e) => HandleData(e)}
                    value={data.Description}
                    labelText="Description"
                    id="Description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </GridItem>
              </GridContainer>
              {validated ? (
                <></>
              ) : (
                <Danger>Input all the mandatory fields</Danger>
              )}
            </CardBody>

            <CardFooter>
              <Button onClick={HandleClear}>Clear</Button>
              <Button onClick={HandleSave} color="info">
                Save
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Container>
        <Card>
          <CardBody>
            <CustomTable
              tableHeaderColor="info"
              tableHead={[
                "Id",
                "Type",
                "Name",
                "Projects",
                "Renewal Date",
                "Description",
                "Actions",
              ]}
              tableData={WorkingData}
              noAction={false}
              setEdit={setEdit}
              Images={false}
              loading={false}
              dateCheck={true}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

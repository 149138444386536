// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";

//Components
import TicketStatusDev from "views/TicketStatusDev/TicektStatusDev";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DailyReport from "views/DailyReport/DailyReport";
import TicketStatusDevMaintenance from "views/TicketStatusDev/TicektStatusDevMaintenance";
import DashboardDev from "views/Dashboard/DashboardDev";
import DueDateExpired from "views/DueDateExpired/DueDateExpired";
import { LockClock } from "@mui/icons-material";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardDev,
    layout: "/admin",
  },
  {
    path: "/tickets-development",
    name: "Tickets-Development",
    icon: ConfirmationNumberSharpIcon,
    component: TicketStatusDev,
    layout: "/admin",
  },
  {
    path: "/tickets-maintenance",
    name: "Tickets-Maintenance",
    icon: ConfirmationNumberSharpIcon,
    component: TicketStatusDevMaintenance,
    layout: "/admin",
  },
  {
    path: "/daily-log",
    name: "Daily Work Log",
    icon: AssessmentIcon,
    component: DailyReport,
    layout: "/admin",
  },
  {
    path: "/due-dates",
    name: "Due-Date Expired",
    icon: LockClock,
    component: DueDateExpired,
    layout: "/admin",
  },
];

export default dashboardRoutes;

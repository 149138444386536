import React, { useEffect, useState } from "react";
import { Domain } from "Domain";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/TableDueDate1.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import axios from "axios";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";

import EmptyTable from "components/EmptyTable";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

// import { data } from "./data.json";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryGrey: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgb(128,128,128)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      fontWeight: "500",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStyle: {
    padding: "10px",
    paddingRight: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function DueDateExpired() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [edit, setEdit] = useState([]);
  const [ExpiredTickets, setExpiredTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);

  const [type, ChangeType] = useState("1");

  const UserDetails = JSON.parse(window.localStorage.getItem("userdetails"));
  // const [AssignView, setAssignView] = useState(false);
  // const [ViewStaff, setViewStaff] = useState(false);

  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Form Data
  // const [data, setData] = React.useState({
  //   Id: 0,
  //   FullName: "",
  //   Mobile: "",
  //   Email: "",
  //   Password: "",
  //   Usertype: "Developer",
  //   Image: "",
  // });

  //Function for Validating fields
  // function ValidateFields() {
  //   if (data.FullName == "") {
  //     return false;
  //   } else if (data.Mobile == "") {
  //     return false;
  //   } else if (data.Email == "") {
  //     return false;
  //   } else if (data.Password == "") {
  //     return false;
  //   } else if (data.Usertype == "") {
  //     return false;
  //   } else return true;
  // }

  //To get Developer list from database
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Type", type);
    axios({
      method: "post",
      url: Domain + "/GetExpiredTicketsByDevId.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Developers Response:", response.data);
        if (response.data.TotalCount != 0) {
          setExpiredTickets(response.data.data);
          console.log("setDevelopers ", response.data.data);
          setLoading(false);
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [type, refresh]);

  const ExpiredTickets1 = ExpiredTickets.map((d) => ({
    Status: d.Status,
    Id: d.id,
    CreateDate: d.CreateDate,
    DueDate: d.DueDate,
    Project: d.Project,
    Subject: d.Subject,
    DueDateExtReq: d.DueDateExtReq,
  }));

  // Function to Req due date extension
  function DueDateExtentionReq(id) {
    let formData = new FormData();
    formData.append("Id", id);
    formData.append("Type", type);

    axios({
      method: "post",
      url: Domain + "/ReqDateExtension.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.success) {
          showSavedNotification();
          console.log("saved");
          if (refresh) setRefresh(false);
          else setRefresh(true);
        } else {
          showDeletedNotification;
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        setDeleted(true);
        console.log(response);
      });
  }

  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message="Request sent!"
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error Try agian!"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />
      <GridContainer>
        <GridItem>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={type}
          >
            <FormControlLabel
              onChange={() => ChangeType("1")}
              value="1"
              control={<Radio />}
              label="Development"
            />
            <FormControlLabel
              onChange={() => ChangeType("2")}
              value="2"
              control={<Radio />}
              label="Maintenance"
            />
          </RadioGroup>
        </GridItem>
      </GridContainer>
      {/* {AddStaff ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <form>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>Developer Details</h4>
                  <p className={classes.cardCategoryWhite}>Enter the Details</p>
                </CardHeader>

                <CardBody>
                  <Card className={classes.cardStyle}>
                    <p className={classes.cardCategoryGrey}>Login Details</p>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          onChange={(e) => HandleData(e)}
                          value={data.FullName}
                          labelText="Name"
                          id="FullName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          onChange={(e) => HandleData(e)}
                          value={data.Mobile}
                          labelText="Mobile"
                          id="Mobile"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          onChange={(e) => HandleData(e)}
                          value={data.Email}
                          labelText="Email"
                          id="Email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          type="password"
                          onChange={(e) => HandleData(e)}
                          value={data.Password}
                          labelText="Password"
                          id="Password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <CustomFileInput
                          setFiles={setFiles}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Click here to upload an image",
                          }}
                          endButton={{
                            buttonProps: {
                              round: true,
                              color: "info",
                              justIcon: true,
                            },
                            icon: <AttachFile />,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </Card>

                  <GridContainer>
                    <GridItem xs={12} sm={5} md={4}>
                      {" "}
                      {validated ? (
                        <></>
                      ) : (
                        <Danger>Please enter all the details to save</Danger>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button onClick={() => setAddStaff(false)} color="danger">
                    Cancel
                  </Button>

                  <Button onClick={HandleClear}>Clear</Button>
                  <Button onClick={HandleSave} color="info">
                    Save
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <>
          <Button onClick={() => setAddStaff(true)} color="info">
            Add Developer
          </Button>
        </>
      )} */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>
                List of Due date Expired Tickets{" "}
              </h4>
            </CardHeader>
            <CardBody>
              {empty ? (
                <EmptyTable />
              ) : (
                <Table
                  tableHeaderColor="info"
                  tableHead={[
                    "Status",
                    "Id",
                    "Create Date",
                    "Due Date",
                    "Project",
                    "Subject",
                    "Action",
                  ]}
                  ExpiredTickets={ExpiredTickets1}
                  noAction={false}
                  setEdit={DueDateExtentionReq}
                  Images={false}
                  loading={loading}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

import React, { useEffect } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import LoginPage from "layouts/LoginPage/LoginPage";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import HodLayout from "layouts/HodLayout";
import UserProfile from "views/UserProfile/UserProfile";
import DevLayout from "layouts/DevLayout";
import axios from "axios";
import { Domain } from "Domain";
import { useState } from "react";
import { Card, Dialog, Slide } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomTable from "components/Table/RenewalDateTable";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// import DailyReport from "views/DailyReport/DailyReport";
// import DailyReportAdmin from "views/DailyReportAdmin/DailyReportAdmin";
export default function App() {
  const [loggedIn, setLoggedin] = React.useState(undefined);

  const [notify, setNotification] = React.useState(false);
  const [bc, setBC] = React.useState(false);
  const [userType, setUserType] = React.useState("");
  const [userdetails, setUserdetails] = React.useState([]);
  useEffect(() => {
    if (loggedIn == undefined) {
      setLoggedin(JSON.parse(window.localStorage.getItem("LoggedIn")));
      setUserType(window.localStorage.getItem("UserType"));
      setUserdetails(JSON.parse(window.localStorage.getItem("userdetails")));
    }
  }, []);
  // console.log(
  //   "loggedIn:",
  //   loggedIn,
  //   "userType:",
  //   userType,
  //   "userdetails:",
  //   userdetails
  // );
  useEffect(() => {
    // console.log("logedin changed:", loggedIn);
    if (loggedIn != undefined)
      window.localStorage.setItem("LoggedIn", JSON.stringify(loggedIn));
    else window.localStorage.setItem("LoggedIn", JSON.stringify(false));

    if (userType != "") window.localStorage.setItem("UserType", userType);
    if (userdetails != [])
      window.localStorage.setItem("userdetails", JSON.stringify(userdetails));
  }, [loggedIn, userType, userdetails]);
  const showNotification = () => {
    if (!bc) {
      setBC(true);
      setTimeout(function () {
        setBC(false);
      }, 3000);
    }
  };
  useEffect(() => {
    // console.log(userType);
    if (notify) {
      showNotification();
    }
  }, [notify, userType]);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 9);
  const cur_date = currentDate.toLocaleDateString("en-CA");
  const [ExpiredData, setExpiredData] = useState([]);
  const [Model, setModel] = useState(false);
  console.log("ExpiredData::", ExpiredData);

  useEffect(() => {
    let formData = new FormData();
    formData.append("CurrentDate", cur_date);
    axios({
      method: "post",
      url: Domain + "/GetExpiredRenewals.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("RenewalDates Response:", response.data);

        if (response.data.success) {
          setExpiredData(response.data.data);
          if (response.data.data.length > 0) {
            setModel(true);
          } else {
            setModel(false);
          }
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response);
      });
  }, []);
  let ExpiredData1 =
    ExpiredData &&
    ExpiredData.map((d, key) => ({
      Id: key + 1,
      Type: d.Type,
      Name: d.Name,
      Project: d.Project,
      RenewalDate: d.RenewalDate,
      Description: d.Description.slice(0, 50) + "...",
    }));
  return (
    <>
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message={"Logged in as " + userType}
        open={bc}
        closeNotification={() => setBC(false)}
        close
      />
      {/* {ExpiredData.length > 0 && alert("hii")} */}

      <Dialog
        open={Model}
        onClose={() => setModel(false)}
        TransitionComponent={Transition}
      >
        <Card>
          <Card>
            <br />
            <CardHeader color="info">
              Renewal Notifications of Services
            </CardHeader>
            <CardBody>
              {" "}
              <CustomTable
                tableHeaderColor="info"
                tableHead={[
                  "Sl.No",
                  "Type",
                  "Name",
                  "Projects",
                  "Renewal Date",
                  "Description",
                ]}
                tableData={ExpiredData1}
                noAction={true}
                Images={false}
                loading={false}
              />
            </CardBody>
          </Card>
        </Card>{" "}
      </Dialog>

      <BrowserRouter>
        <>
          {loggedIn ? (
            <div>
              {(() => {
                if (userType == "Admin") {
                  return (
                    <Switch>
                      <Route path="/admin">
                        <Admin setLoggedin={setLoggedin} />
                      </Route>
                      <Route path="/user">
                        <UserProfile
                          userdetails={userdetails}
                          setLoggedin={setLoggedin}
                        />
                      </Route>
                      <Route path="/daily-report-full">
                        <UserProfile
                          userdetails={userdetails}
                          setLoggedin={setLoggedin}
                        />
                      </Route>
                      <Redirect from="/" to="/admin/dashboard" />
                    </Switch>
                  );
                } else if (userType == "Developer") {
                  return (
                    <Switch>
                      <Route path="/admin">
                        <DevLayout setLoggedin={setLoggedin} />
                      </Route>
                      <Redirect from="/" to="/admin/dashboard" />
                    </Switch>
                  );
                } else if (userType == "HOD") {
                  return (
                    <Switch>
                      <Route path="/admin">
                        <HodLayout setLoggedin={setLoggedin} />
                      </Route>
                      <Redirect from="/" to="/admin/dashboard" />
                    </Switch>
                  );
                }
              })()}
            </div>
          ) : (
            <Switch>
              <Route path="/login">
                <LoginPage
                  setLoggedin={setLoggedin}
                  setNotification={setNotification}
                  setUserType={setUserType}
                  setUserdetails={setUserdetails}
                />
              </Route>

              <Redirect from="/" to="/login" />
            </Switch>
          )}
        </>
      </BrowserRouter>
    </>
  );
}

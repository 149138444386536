import Dashboard from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardPage from "views/Dashboard/Dashboard.js";
import ProjectReport from "views/ProjectReport/ProjectReport";
import Staffs from "views/Staffs/Staffs";
import TicketStatusAdmin from "views/TicketStatusAdmin/TicketStatusAdmin";
import WorkLog from "views/WorkLog/WorkLog";
import DescriptionIcon from "@material-ui/icons/Description";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeveloperReport from "views/DeveloperReport/DeveloperReport";
import RaiseTicket from "views/RaiseTicket/RaiseTicket";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DailyReportAdmin from "views/DailyReportAdmin/DailyReportAdmin";
import TodayIcon from "@material-ui/icons/Today";
import TicketStatusAdminMaintenance from "views/TicketStatusAdmin/TicketStatusAdminMaintenance";
import RenewalDates from "views/RenewalDates/RenewalDates";
import { RepeatOneTwoTone } from "@material-ui/icons";
import DueDateExtentionRequestPage from "views/DueDateExtentionRequestPage/DueDateExtentionRequestPage";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/raise-tickets",
    name: "Raise Ticket",
    icon: AddBoxIcon,
    component: RaiseTicket,
    layout: "/admin",
  },
  {
    path: "/tickets-development",
    name: "Tickets-Development",
    icon: "content_paste",
    component: TicketStatusAdmin,
    layout: "/admin",
  },
  {
    path: "/ticket-maintenance",
    name: "Ticket-Maintenance",
    icon: "content_paste",
    component: TicketStatusAdminMaintenance,
    layout: "/admin",
  },
  {
    path: "/due-dates",
    name: "DueDate Requests",
    icon: MarkEmailUnreadIcon,
    component: DueDateExtentionRequestPage,
    layout: "/admin",
  },
  {
    path: "/developers",
    name: "Developers",
    icon: PeopleIcon,
    component: Staffs,
    layout: "/admin",
  },
  {
    path: "/daily-report",
    name: "Daily Work Report",
    icon: TodayIcon,
    component: DailyReportAdmin,
    layout: "/admin",
  },
  {
    path: "/work-log",
    name: "Work Log",
    icon: DescriptionIcon,
    component: WorkLog,
    layout: "/admin",
  },
  {
    path: "/project-report",
    name: "Project Report",
    icon: BarChartIcon,
    component: ProjectReport,
    layout: "/admin",
  },
  {
    path: "/developer-report",
    name: "Developer Report",
    icon: FileCopyIcon,
    component: DeveloperReport,
    layout: "/admin",
  },
  {
    path: "/renewal-dates",
    name: "Renewal Dates",
    icon: RepeatOneTwoTone,
    component: RenewalDates,
    layout: "/admin",
  },
];

export default dashboardRoutes;

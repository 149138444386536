import React, { useEffect, useState } from "react";
import axios from "axios";
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
import Update from "@material-ui/icons/Update";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import connectionFailed from "assets/img/connectionfailed.jpg";
import { Link } from "react-router-dom";
import CardBody from "components/Card/CardBody";
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [Raised, setRaised] = useState(0);
  const [Assigned, setAssigned] = useState(0);
  const [Working, setWorking] = useState(0);
  const [Completed, setCompleted] = useState(0);
  const [Pending, setPending] = useState(0);
  const [Confirmed, setConfirmed] = useState(0);
  const [RaisedMaintenance, setRaisedMaintenance] = useState(0);
  const [AssignedMaintenance, setAssignedMaintenance] = useState(0);
  const [WorkingMaintenance, setWorkingMaintenance] = useState(0);
  const [CompletedMaintenance, setCompletedMaintenance] = useState(0);
  const [PendingMaintenance, setPendingMaintenance] = useState(0);
  const [ConfirmedMaintenance, setConfirmedMaintenance] = useState(0);

  const [netWorkError, setNetWorkError] = useState(false);
  //To get Raised Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);

    formData.append("Status", "Raised");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setRaised(response.data.TotalCount);
          window.localStorage.setItem(
            "Raised_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        if (response.message == "Network Error") {
          setNetWorkError(true);
        }
      });
  }, []);

  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);

    formData.append("Status", "Assigned");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setAssigned(response.data.TotalCount);
          window.localStorage.setItem(
            "Assigned_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);

    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setWorking(response.data.TotalCount);
          window.localStorage.setItem(
            "Working_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);

    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setCompleted(response.data.TotalCount);
          window.localStorage.setItem(
            "Completed_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);

    formData.append("Status", "Pending");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setPending(response.data.TotalCount);
          window.localStorage.setItem(
            "Pending_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  //To get Confirmed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);

    formData.append("Status", "Verified");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setConfirmed(response.data.TotalCount);
          window.localStorage.setItem(
            "Pending_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  /////////////////////////////////////////
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Raised");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setRaisedMaintenance(response.data.TotalCount);
          window.localStorage.setItem(
            "Raised_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        if (response.message == "Network Error") {
          setNetWorkError(true);
        }
      });
  }, []);

  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Assigned");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setAssignedMaintenance(response.data.TotalCount);
          window.localStorage.setItem(
            "Assigned_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setWorkingMaintenance(response.data.TotalCount);
          window.localStorage.setItem(
            "Working_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setCompletedMaintenance(response.data.TotalCount);
          window.localStorage.setItem(
            "Completed_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Pending");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setPendingMaintenance(response.data.TotalCount);
          window.localStorage.setItem(
            "Pending_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  //To get Confirmed Tickets list from database

  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Verified");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setConfirmedMaintenance(response.data.TotalCount);
          window.localStorage.setItem(
            "Pending_tickets",
            JSON.stringify(response.data.TotalCount)
          );
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  return (
    <div>
      {netWorkError ? (
        <center>
          <img style={{ maxHeight: "400px" }} src={connectionFailed} alt="" />
        </center>
      ) : (
        <>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: "/admin/tickets-development",
                  state: "0",
                }}
              >
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>
                      <b>Recently Raised Tickets</b>
                    </h4>
                    <h3 className={classes.cardTitle}>
                      <b>{Raised + RaisedMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <b className={classes.cardCategory}>
                      {" "}
                      Development Tickets:
                    </b>
                    <b className={classes.cardTitle}>
                      <b>{Raised}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Maintenance Tickets:</b>
                    <b className={classes.cardTitle1}>
                      <b>{RaisedMaintenance}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Total Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{Raised + RaisedMaintenance}</b>
                    </b>
                  </CardBody>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: "/admin/tickets-development",
                  state: "1",
                }}
              >
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>
                      <b>Assigned Tickets Count</b>
                    </h4>
                    <h3 className={classes.cardTitle}>
                      <b>{Assigned + AssignedMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <b className={classes.cardCategory}>
                      {" "}
                      Development Tickets:
                    </b>
                    <b className={classes.cardTitle}>
                      <b>{Assigned}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Maintenance Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{AssignedMaintenance}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Total Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{Assigned + AssignedMaintenance}</b>
                    </b>
                  </CardBody>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: "/admin/tickets-development",
                  state: "2",
                }}
              >
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>
                      <b>Working Tickets Count </b>
                    </h4>
                    <h3 className={classes.cardTitle}>
                      <b>{Working + WorkingMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <b className={classes.cardCategory}>
                      {" "}
                      Development Tickets:
                    </b>
                    <b className={classes.cardTitle}>
                      <b>{Working}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Maintenance Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{WorkingMaintenance}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Total Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{Working + WorkingMaintenance}</b>
                    </b>
                  </CardBody>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: "/admin/tickets-development",
                  state: "3",
                }}
              >
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>
                      <b>Completed Ticket Count</b>
                    </h4>
                    <h3 className={classes.cardTitle}>
                      <b>{Completed + CompletedMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <b className={classes.cardCategory}>
                      {" "}
                      Development Tickets:
                    </b>
                    <b className={classes.cardTitle}>
                      <b>{Completed}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Maintenance Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{CompletedMaintenance}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Total Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{Completed + CompletedMaintenance}</b>
                    </b>
                  </CardBody>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Link
                to={{
                  pathname: "/admin/tickets-development",
                  state: "4",
                }}
              >
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>
                      <b>Pending Ticket Count</b>
                    </h4>
                    <h3 className={classes.cardTitle}>
                      <b>{Pending + PendingMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <b className={classes.cardCategory}>
                      {" "}
                      Development Tickets:
                    </b>
                    <b className={classes.cardTitle}>
                      <b>{Pending}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Maintenance Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{PendingMaintenance}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Total Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{Pending + PendingMaintenance}</b>
                    </b>
                  </CardBody>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <Link to="/admin/ticket-status">
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>
                      <b>Verified Ticket Count</b>
                    </h4>
                    <h3 className={classes.cardTitle}>
                      <b>{Confirmed + ConfirmedMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <b className={classes.cardCategory}>
                      {" "}
                      Development Tickets:
                    </b>
                    <b className={classes.cardTitle}>
                      <b>{Confirmed}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Maintenance Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{ConfirmedMaintenance}</b>
                    </b>
                    <br />
                    <b className={classes.cardCategory}>Total Tickets:</b>
                    <b className={classes.cardTitle}>
                      <b>{Confirmed + ConfirmedMaintenance}</b>
                    </b>
                  </CardBody>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
          </GridContainer>
        </>
      )}
    </div>
  );
}

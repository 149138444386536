import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import PropTypes from "prop-types";

import routes from "DevRoutes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/favicon.png";
import { useState } from "react";
import { Domain } from "Domain";
import { useEffect } from "react";
import axios from "axios";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function DevLayout({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const UserDetails = JSON.parse(window.localStorage.getItem("userdetails"));
  console.log("==================User Details===================", UserDetails);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const getRoute = () => {
  //   return window.location.pathname !== "/admin/user";
  // };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const [countDevelopment, SetCountDevelopment] = useState(0);
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Type", 1);
    axios({
      method: "post",
      url: Domain + "/GetExpiredTicketsByDevId.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Developers Response:", response.data);
        if (response.data.TotalCount != 0) {
          SetCountDevelopment(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response);
      });
  }, []);
  const [countMaintenance, SetCountMaintenance] = useState(0);
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Type", 2);
    axios({
      method: "post",
      url: Domain + "/GetExpiredTicketsByDevId.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Developers Response:", response.data);
        if (response.data.TotalCount != 0) {
          SetCountMaintenance(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response);
      });
  }, []);
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

        <Sidebar
          routes={routes}
          counter={countDevelopment + countMaintenance}
          logoText={UserDetails.FullName}
          logo={logo}
          image=""
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color="blue"
          {...rest}
        />
        <Navbar
          setLoggedin={rest.setLoggedin}
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
DevLayout.propTypes = {
  userType: PropTypes.any,
};

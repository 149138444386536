import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";
import LoadingOverlay from "react-loading-overlay";

import axios from "axios";
import usePagination from "components/Pagination/Pagination1";

import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";
import CardFooter from "components/Card/CardFooter";
import { Pagination } from "@mui/lab";
import Chip from "@mui/material/Chip";
import {
  AppBar,
  Box,
  CardMedia,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import placeholder from "assets/img/no_img.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TicketStatusDevMaintenance() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const userdetails = window.localStorage.getItem("userdetails");
  const UserDetails = JSON.parse(userdetails);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [Raised, setRaised] = useState([]);
  const [Assigned, setAssigned] = useState([]);
  const [Working, setWorking] = useState([]);
  const [Completed, setCompleted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [RaisedEmpty, setRaisedEmpty] = useState(true);
  const [AssignedEmpty, setAssignedEmpty] = useState(true);
  const [WorkingEmpty, setWorkingEmpty] = useState(true);
  const [CompletedEmpty, setCompletedEmpty] = useState(true);
  const [viewData, setviewData] = useState(false);
  const [edit, setEdit] = useState("");
  const [DevError, setError] = useState(false);
  const [TicketDetials, setTicketDetails] = useState([]);
  const [Pending, setPending] = useState([]);
  const [PendingEmpty, setPendingEmpty] = useState(true);
  const [refresh, setRefresh] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const NowDate = new Date().toLocaleDateString("en-CA");
  const [data, setData] = React.useState({
    Hours: "",
  });
  const [confirmModal1, setConfirmModal1] = useState(false);

  function ReverseStatus() {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", "");
    formData.append("DeveloperName", "");
    formData.append("Id", TicketDetials.id);
    formData.append("Status", "Raised");
    formData.append("Flag", "Normal");
    formData.append("Hours", "");

    axios({
      method: "post",
      url: Domain + "/Assign_toDeveloper.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        console.log(
          "============Status Changed to raised Response:",
          response.data
        );
        //handle success
        if (response.data.success) {
          console.log(
            "============Status Changed to raised Response:",
            response.data
          );
          setConfirmModal(false);
          HandleRefresh;
          HandleView();

          // showSavedNotification();
          // setData({ Hours: "" });
        } else {
          HandleView();
          showDeletedNotification;
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        showDeletedNotification;
      });
  }
  //Function to handle Data input
  function HandleData(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    console.log(newData);
  }
  useEffect(() => {
    console.log(RaisedData, RaisedEmpty);
  }, []);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };
  //Refresh Function
  const HandleRefresh = () => {
    if (!refresh) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  };
  //To get Raised Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("Status", "Raised");
    formData.append("DeveloperId", UserDetails.id);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Raised Response:", response);
        if (response.data.success) {
          if (response.data.TotalCount != 0) {
            setRaised(response.data.data);
            setLoading(false);
            setRaisedEmpty(false);
          } else {
            setRaisedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "Assigned");
    formData.append("TeamId", UserDetails.TeamId);

    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Assigned Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setAssigned(response.data.data);
            setAssignedEmpty(false);
            setLoading(false);
          }
        } else {
          setAssignedEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Working Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setWorking(response.data.data);
            setWorkingEmpty(false);
            setLoading(false);
          }
        } else {
          setWorkingEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Completed Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setCompleted(response.data.data);
            setLoading(false);
            setCompletedEmpty(false);
          }
        } else {
          setCompletedEmpty(true);
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("Status", "Pending");
    formData.append("DeveloperId", UserDetails.id);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Pending Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setPending(response.data.data);
            setLoading(false);
            setPendingEmpty(false);
          } else {
            setPendingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  const PendingData = Pending.map((d) => ({
    Status: <Chip label="Pending" color="error" />,
    Id: d.id,
    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),
    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  //Maping Data to tables
  const RaisedData = Raised.map((d) => ({
    Status: <Chip label="Raised" color="warning" />,
    Id: d.id,
    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),

    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),

    Image: d.Image,
  }));
  const AssignedData = Assigned.map((d) => ({
    Status: (
      <>
        {d.Flag == "Important" ? (
          <>
            {d.DeveloperId != UserDetails.id ? (
              <canter>
                <Chip label={"Assigned "} color="error" />
                {d.DeveloperName}
              </canter>
            ) : (
              <Chip label="Assigned" color="error" />
            )}
          </>
        ) : (
          <>
            {d.DeveloperId != UserDetails.id ? (
              <>
                <Chip label={"Assigned"} color="primary" />
                <br />
                {d.DeveloperName}
              </>
            ) : (
              <Chip label="Assigned" color="primary" />
            )}
          </>
        )}
      </>
    ),
    Id: d.id,
    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),
    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  const WorkingData = Working.map((d) => ({
    Status: <Chip label="Working" color="info" />,
    Id: d.id,
    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),
    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  const CompletedData = Completed.map((d) => ({
    Id: d.id,
    Status: <Chip label="Completed" color="success" />,

    Date: d.CreateDate.split(" ")[0],
    Time: d.CreateDate.split(" ")[1],
    DueDate: d.DueDate,
    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  // =====================================
  function HandleView() {
    if (edit != "") {
      if (viewData) {
        setviewData(false);
        setEdit("");
      } else {
        setviewData(true);
      }
    } else {
      setviewData(false);
    }
  }
  //Edit Button Clicked
  useEffect(() => {
    if (edit != "") {
      let formData = new FormData();
      formData.append("Type", 2);
      formData.append("Id", edit);
      axios({
        method: "post",
        url: Domain + "/GetTicketById.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("================Response for Edit:", response.data);
          if (response.data.success) {
            setTicketDetails(response.data.data);
            HandleView();
          }
        })
        .catch(function (response) {
          //handle error
          showDeletedNotification();
          console.log("Error:", response);
        });
    }
  }, [edit]);

  //To get Developer list from database
  // useEffect(() => {
  //   let formData = new FormData();
  //   formData.append("Usertype", "Developer");
  //   axios({
  //     method: "post",
  //     url: Domain + "/getall_users.php",
  //     data: formData,
  //     config: { headers: { "Content-Type": "multipart/form-data" } },
  //   })
  //     .then(function (response) {
  //       //handle success
  //       // console.log("Response:", response.data);
  //       setDevelopers(response.data);
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log(response);
  //     });
  // }, []);
  // console.log(Developers);
  const StatusList = [
    { value: 1, label: "Working" },
    { value: 3, label: "Pending" },
  ];
  const StatusList2 = [
    { value: 2, label: "Completed" },
    { value: 3, label: "Pending" },
  ];
  const StatusList1 = [
    { value: 1, label: "Working" },
    { value: 2, label: "Completed" },
  ];
  const [StatusValues, SetStatusValues] = React.useState({
    Id: null,
    label: "",
  });
  // console.log("selected ", StatusValues);
  // console.log("Name  Id:", JSON.parse(userdetails));

  function ChangeStatus() {
    if (StatusValues.Id != null) {
      setError(false);
      let formData = new FormData();
      formData.append("Type", 2);
      formData.append("DeveloperId", UserDetails.id);
      formData.append("DeveloperName", UserDetails.FullName);
      formData.append("Id", TicketDetials.id);
      formData.append("Status", StatusValues.Label);
      formData.append("Flag", "");
      formData.append("Hours", data.Hours);

      axios({
        method: "post",
        url: Domain + "/Assign_toDeveloper.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          if (response.data.success) {
            // console.log("============Status Changed Response:", response.data);
            showSavedNotification();
            HandleView();
            setData({ Hours: "" });
          } else {
            HandleView();
            showDeletedNotification;
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification;
        });
    } else {
      setError(true);
    }
  }
  // =======================================
  const count = Math.ceil(CompletedData.length / 10);
  let [page, setPage] = useState(1);
  const DATA = usePagination(CompletedData, 10);
  const handleChange = (e, p) => {
    setPage(p);
    DATA.jump(p);
  };
  function SendExtendReq() {
    let formData = new FormData();
    formData.append("Id", edit);
    formData.append("Type", 2);

    axios({
      method: "post",
      url: Domain + "/ReqDateExtension.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        if (response.data.success) {
          setConfirmModal1(false);
          console.log("saved");
          setSuccessMessage("Requestet Sent!");
          showSavedNotification();
        } else {
          showDeletedNotification;
          console.log("failed");
        }
      })
      .catch(function (response) {
        //handle error
        setDeleted(true);
        console.log(response);
      });
  }
  const [successMessage, setSuccessMessage] = useState(
    "Status Changed Successfully!"
  );
  return (
    <>
      <Snackbar
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="bc"
        color="danger"
        icon={AddAlert}
        message="Error Please try again!"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />

      <Dialog
        fullScreen
        open={viewData}
        onClose={HandleView}
        TransitionComponent={Transition}
      >
        <AppBar color="info" sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={HandleView}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <Card>
          <br />
          <GridContainer>
            <GridItem md={12} sm={12} lg={4}>
              {TicketDetials.Image == "" ? (
                <center>
                  <img
                    style={{
                      maxHeight: "200px",
                    }}
                    src={placeholder}
                    alt=""
                  />
                </center>
              ) : (
                <center>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Domain + "/images/" + TicketDetials.Image}
                  >
                    <Card sx={{ maxWidth: 345 }}>
                      <Box sx={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          height="400"
                          image={Domain + "/images/" + TicketDetials.Image}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            bgcolor: "rgba(0, 0, 0, 0.54)",
                            color: "white",
                            padding: "10px",
                          }}
                        >
                          <Typography variant="body2">View Image</Typography>
                        </Box>
                      </Box>
                    </Card>
                  </a>
                </center>
              )}
            </GridItem>
            <GridItem md={12} sm={12} lg={6}>
              Ticket Number : <Chip label={TicketDetials.id} color="info" />,
              <br />
              <table>
                <tr>
                  <td width="150px">Ticket Status </td>
                  <td>
                    : <b>{TicketDetials.Status}</b>
                  </td>
                </tr>

                <tr>
                  <td>Name </td>
                  <td>
                    : <b>{TicketDetials.FullName}</b>
                  </td>
                </tr>
                <tr>
                  <td>Subject </td>
                  <td>
                    : <b>{TicketDetials.Subject}</b>
                  </td>
                </tr>
                <tr>
                  <td>Project Name </td>
                  <td>
                    : <b>{TicketDetials.Project}</b>
                  </td>
                </tr>
                <tr>
                  <td>Issue Description </td>
                  <td>
                    : <b>{TicketDetials.Description}</b>
                  </td>
                </tr>
                <tr>
                  <td>Ticket Raised Date </td>
                  <td>
                    : <b>{TicketDetials.CreateDate}</b>
                  </td>
                </tr>
              </table>
              {TicketDetials.Status == "Working" ? (
                <>
                  <GridItem xs={12} sm={12} md={6}>
                    <SingleSelect
                      noOptionsMessage=".."
                      placeholder="Select Status"
                      Options={StatusList2}
                      setValue={SetStatusValues}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type="number"
                      onChange={(e) => HandleData(e)}
                      value={data.Hours}
                      labelText="Total Hours"
                      id="Hours"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </>
              ) : (
                <>
                  {TicketDetials.Status == "Completed" ? (
                    <></>
                  ) : (
                    <>
                      {TicketDetials.Status == "Pending" ? (
                        <>
                          {" "}
                          <GridItem xs={12} sm={12} md={6}>
                            <SingleSelect
                              noOptionsMessage=".."
                              placeholder="Select Status"
                              Options={StatusList1}
                              setValue={SetStatusValues}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                          {StatusValues.Label == "Completed" ? (
                            <>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  type="number"
                                  onChange={(e) => HandleData(e)}
                                  value={data.Hours}
                                  labelText="Total Hours"
                                  id="Hours"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </GridItem>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <GridItem xs={12} sm={12} md={6}>
                            <SingleSelect
                              noOptionsMessage=".."
                              placeholder="Select Status"
                              Options={StatusList}
                              setValue={SetStatusValues}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </GridItem>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {DevError ? (
                <>
                  <Danger>Please Select a Status first</Danger>
                </>
              ) : (
                <></>
              )}
              <GridContainer className={classes.pullRight}>
                {TicketDetials.Status == "Completed" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <Button color="info" round onClick={ChangeStatus}>
                      Change Status
                    </Button>
                    {TicketDetials.Status == "Working" && (
                      <Button
                        color="warning"
                        round
                        onClick={() => setConfirmModal1(true)}
                      >
                        Extend Due Date
                      </Button>
                    )}
                    <Button
                      color="danger"
                      round
                      onClick={() => setConfirmModal(true)}
                    >
                      Help Me!
                    </Button>
                  </>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <br />
        </Card>
      </Dialog>
      <Dialog
        open={confirmModal1}
        onClose={() => setConfirmModal1(false)}
        TransitionComponent={Transition}
      >
        <Card>
          <CardBody>
            {" "}
            <center>
              <b>
                {UserDetails.FullName === "Hima" ? (
                  <>{UserDetails.FullName}, Are</>
                ) : (
                  <>Do you want to request for duedate extension?</>
                )}
              </b>

              <br />
              <Button round onClick={() => setConfirmModal1(false)}>
                No
              </Button>
              <Button color="success" round onClick={SendExtendReq}>
                Yes
              </Button>
            </center>
          </CardBody>
        </Card>
      </Dialog>
      <Dialog
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        TransitionComponent={Transition}
      >
        <Card>
          <CardBody>
            {" "}
            <center>
              <b>
                {UserDetails.FullName === "Hima" ? (
                  <>
                    {UserDetails.FullName}, Its Shame on you! I thought you
                    could do this.
                  </>
                ) : (
                  <>
                    Are you Sure {UserDetails.FullName} you can&apos;t do the
                    task?
                  </>
                )}
              </b>
              <p>
                Click Cansel and make it happen or Click Riverse and move on!
              </p>
              <br />
              <Button round onClick={() => setConfirmModal(false)}>
                Cancel
              </Button>
              <Button color="danger" round onClick={ReverseStatus}>
                Riverse
              </Button>
            </center>
          </CardBody>
        </Card>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button round onClick={HandleRefresh}>
            Refresh
          </Button>
          <NavPills
            active={0}
            color="info"
            tabs={[
              {
                tabButton: "Assigned Tickets - " + Assigned.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Assigned Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Assigned Tickets will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {AssignedEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Id",
                                    "Create Date",
                                    "Due Date",
                                    "Project",
                                    "Subject",
                                    "Action",
                                  ]}
                                  tableData={AssignedData}
                                  noAction={false}
                                  setEdit={setEdit}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "Working Tickets - " + Working.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Working Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Working Tickets will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {WorkingEmpty ? (
                                <p>empty</p>
                              ) : (
                                <>
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Id",
                                      "Create Date",
                                      "Due Date",
                                      "Project",
                                      "Subject",
                                      "Action",
                                    ]}
                                    tableData={WorkingData}
                                    noAction={false}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                </>
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "Completed Tickets - " + Completed.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Completed Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Completed Tickets will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {CompletedEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Id",
                                    "Create Date",
                                    "Time",
                                    "Due Date",
                                    "Project",
                                    "Subject",
                                    "Action",
                                  ]}
                                  tableData={DATA.currentData()}
                                  noAction={false}
                                  setEdit={setEdit}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                          <CardFooter>
                            <Pagination
                              count={count}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                              onChange={handleChange}
                              page={page}
                            />
                          </CardFooter>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "Pending Tickets - " + Pending.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Pending Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Pending Tickets will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {PendingEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Id",
                                    "Create Date",
                                    "Due Date",
                                    "Project",
                                    "Subject",
                                    "Action",
                                  ]}
                                  tableData={PendingData}
                                  noAction={false}
                                  setEdit={setEdit}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Domain } from "Domain";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationNumberSharpIcon from "@material-ui/icons/ConfirmationNumberSharp";
import Update from "@material-ui/icons/Update";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import connectionFailed from "assets/img/connectionfailed.jpg";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
const useStyles = makeStyles(styles);

export default function DashboardDev() {
  const UserDetails = JSON.parse(window.localStorage.getItem("userdetails"));

  const classes = useStyles();
  const [Assigned, setAssigned] = useState(0);
  const [Working, setWorking] = useState(0);
  const [Completed, setCompleted] = useState(0);
  const [Pending, setPending] = useState(0);
  const [netWorkError, setNetWorkError] = useState(false);
  console.log("Online::::", navigator.onLine);
  useEffect(() => {
    if (!navigator.onLine) {
      setNetWorkError(true);
    }
  }, []);
  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Assigned");
    formData.append("Type", 1);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setAssigned(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setWorking(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setCompleted(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 1);
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Pending");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setPending(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  ////////////////////////////////---Maintenance Ticket counts----------///////////////////////
  const [AssignedMaintenance, setAssignedMaintenance] = useState(0);
  const [WorkingMaintenance, setWorkingMaintenance] = useState(0);
  const [CompletedMaintenance, setCompletedMaintenance] = useState(0);
  const [PendingMaintenance, setPendingMaintenance] = useState(0);
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Assigned");
    formData.append("Type", 2);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Assigned Maintenance:", response);

        if (response.data.success) {
          setAssignedMaintenance(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error::;", response);
      });
  }, []);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setWorkingMaintenance(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setCompletedMaintenance(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("DeveloperId", UserDetails.id);

    formData.append("Status", "Pending");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        if (response.data.success) {
          setPendingMaintenance(response.data.TotalCount);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);
  return (
    <div>
      {netWorkError ? (
        <center>
          <img style={{ maxHeight: "400px" }} src={connectionFailed} alt="" />
        </center>
      ) : (
        <Container>
          <h4 className={classes.cardTitle}>Development Tickets</h4>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-development">
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Assigned Tickets Count</b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{Assigned}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-development">
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Working Tickets Count </b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{Working}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-development">
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Completed Ticket Count</b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{Completed}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-development">
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Pending Ticket Count</b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{Pending}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
          </GridContainer>
          {/* /////////////////////////////////////////// */}
          <h4 className={classes.cardTitle}>Maintenance Tickets</h4>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-maintenance">
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon color="primary">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Assigned Tickets Count</b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{AssignedMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-maintenance">
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Working Tickets Count </b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{WorkingMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-maintenance">
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Completed Ticket Count</b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{CompletedMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to="/admin/tickets-maintenance">
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <ConfirmationNumberSharpIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      <b>Pending Ticket Count</b>
                    </p>
                    <h3 className={classes.cardTitle}>
                      <b>{PendingMaintenance}</b>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}>
                      <Update />
                      Just Updated
                    </div>
                  </CardFooter>
                </Card>
              </Link>
            </GridItem>
          </GridContainer>
        </Container>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Table from "components/Table/Table.js";
import Table1 from "components/Table/Table1";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
// import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import AddAlert from "@material-ui/icons/AddAlert";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Button from "components/CustomButtons/Button.js";
import { Domain } from "Domain";
// import axios from "axios";
// import Danger from "components/Typography/Danger";
import LoadingOverlay from "react-loading-overlay";
// import ImageUpload from "components/CustomUpload/ImageUpload.js";
// import Accordion from "components/Accordion/Accordion";
// import Pagination from "components/Pagination/Pagination";
import axios from "axios";
// import Success from "components/Typography/Success";

// import SnackbarContent from "components/Snackbar/SnackbarContent";
import SingleSelect from "components/SingleSelect";
import Danger from "components/Typography/Danger";
import Chip from "@mui/material/Chip";
import LinearIndeterminate from "components/LinearLoading/LinearLoading";
import {
  AppBar,
  Box,
  CardMedia,
  Dialog,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import placeholder from "assets/img/no_img.png";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

// import AttachFile from "@material-ui/icons/AttachFile";
// import CustomFileInput from "components/CustomFileInput/CustomFileInput.js";

// import Data from "./Data.json";
const styles = {
  paginationLink: {
    ":first-of-type": {
      marginleft: "0",
    },

    letterSpacing: "unset",
    border: "0",
    borderRadius: "50px !important",
    transition: "all .3s",
    padding: "0px 11px",
    margin: "0 3px",
    minWidth: "30px",
    height: "30px",
    minHeight: "auto",
    lineHeight: "30px",
    fontWeight: "400",
    fontSize: "15px",
    textTransform: "uppercase",
    background: "#0cb4c9",
    position: "relative",
    float: "left",
    textDecoration: "none",
    boxSizing: "border-box",
    "&,&:hover,&:focus": {
      color: "white",
    },
    "&:hover,&:focus": {
      zIndex: "3",
      backgroundColor: "#0cb4c9",
      borderColor: "#000000",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(styles);

export default function TicketStatusAdminMaintenance() {
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [Raised, setRaised] = useState([]);
  const [Assigned, setAssigned] = useState([]);
  const [Working, setWorking] = useState([]);
  const [Completed, setCompleted] = useState([]);
  const [Pending, setPending] = useState([]);
  const [Developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [RaisedEmpty, setRaisedEmpty] = useState(true);
  const [AssignedEmpty, setAssignedEmpty] = useState(true);
  const [WorkingEmpty, setWorkingEmpty] = useState(true);
  const [CompletedEmpty, setCompletedEmpty] = useState(true);
  const [PendingEmpty, setPendingEmpty] = useState(true);
  const [viewData, setviewData] = useState(false);
  const [edit, setEdit] = useState("");
  const [DevError, setError] = useState(false);
  const [TicketDetials, setTicketDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [Verify, setVerify] = useState("");
  const [Message, setMessage] = useState("");
  const NowDate = new Date().toLocaleDateString("en-CA");
  // const UserDetails = JSON.parse(window.localStorage.getItem("userdetails"));
  const [Loading1, setLoading1] = useState(false);
  useEffect(() => {
    if (Verify != "") {
      setLoading1(true);

      console.log("Verify::", Verify);
      setError(false);
      let formData = new FormData();
      formData.append("Type", 2);

      formData.append("Id", Verify);
      formData.append("Status", "Verified");

      axios({
        method: "post",
        url: Domain + "/ChangeTicketStatus.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          if (response.data.success) {
            console.log("============Status Changed Response:", response.data);
            setMessage("Ticket Verified");
            showSavedNotification();
            setLoading1(false);
            HandleRefresh();
          } else {
            showDeletedNotification;
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification();
        });
    }
  }, [Verify]);
  useEffect(() => {
    console.log(RaisedData);
  }, []);
  //Saved Notification trigger
  const showSavedNotification = () => {
    if (!saved) {
      setSaved(true);
      setTimeout(function () {
        setSaved(false);
      }, 3000);
    }
  };
  //Deleted Notification Trigger
  const showDeletedNotification = () => {
    if (!deleted) {
      setDeleted(true);
      setTimeout(function () {
        setDeleted(false);
      }, 3000);
    }
  };

  //To get Raised Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("Status", "Raised");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Raised Response:", response);
        if (response.data.success) {
          if (response.data.TotalCount != 0) {
            setRaised(response.data.data);
            setLoading(false);
            setRaisedEmpty(false);
          } else {
            setRaisedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Assigned Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);
    formData.append("Status", "Assigned");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Assigned Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setAssigned(response.data.data);
            setAssignedEmpty(false);
            setLoading(false);
          } else {
            setAssignedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Working Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Working");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Working Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setWorking(response.data.data);
            setWorkingEmpty(false);
            setLoading(false);
          } else {
            setWorkingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);

  //To get Completed Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Completed");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Completed Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setCompleted(response.data.data);
            setLoading(false);
            setCompletedEmpty(false);
          } else {
            setCompletedEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  //To get Pending Tickets list from database
  useEffect(() => {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", "Pending");
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Pending Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            setPending(response.data.data);
            setLoading(false);
            setPendingEmpty(false);
          } else {
            setPendingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }, [viewData, refresh]);
  //Maping Data to tables
  const RaisedData = Raised.map((d) => ({
    Status: <Chip label="Raised" color="warning" />,
    Id: d.id,
    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),

    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  const AssignedData = Assigned.map((d) => ({
    Status: (
      <>
        {d.Flag == "Important" ? (
          <Chip label="Assigned" color="error" />
        ) : (
          <Chip label="Assigned" color="primary" />
        )}
      </>
    ),
    Id: d.id,
    DeveloperName: (
      <Chip
        onClick={() => HandleDeveloperFilter(d.DeveloperId, d.Status)}
        label={d.DeveloperName}
      />
    ),

    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),

    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  const WorkingData = Working.map((d) => ({
    Status: <Chip label="Working" color="info" />,
    Id: d.id,
    DeveloperName: (
      <Chip
        onClick={() => HandleDeveloperFilter(d.DeveloperId, d.Status)}
        label={d.DeveloperName}
      />
    ),
    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),

    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  const CompletedData = Completed.map((d) => ({
    Status: <Chip color="success" label="Complete" />,
    Id: d.id,
    DeveloperName: (
      <>
        <Chip
          onClick={() => HandleDeveloperFilter(d.DeveloperId, d.Status)}
          label={d.DeveloperName}
        >
          {" "}
        </Chip>
      </>
    ),
    Date: d.CreateDate.split(" ")[0],
    DueDate: d.DueDate,

    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  const PendingData = Pending.map((d) => ({
    Status: <Chip label="Pending" color="error" />,
    Id: d.id,
    DeveloperName: (
      <Chip
        onClick={() => HandleDeveloperFilter(d.DeveloperId, d.Status)}
        label={d.DeveloperName}
      />
    ),

    Date: d.CreateDate.split(" ")[0],
    DueDate:
      d.DueDate < NowDate ? (
        <>
          <Chip label={d.DueDate} color="error" />
          <br />
          <span style={{ color: "red" }}>Due Date Expired</span>{" "}
        </>
      ) : d.DueDate == NowDate ? (
        <>
          <Chip label={d.DueDate} color="warning" />
          <br />
          <span style={{ color: "orange" }}>Due Date Today </span>{" "}
        </>
      ) : (
        d.DueDate
      ),

    Project: d.Project,
    Subject: (
      <>{d.Subject.length > 46 ? d.Subject.slice(0, 46) + "..." : d.Subject}</>
    ),
  }));
  // ======================================
  function HandleView() {
    if (edit != "") {
      if (viewData) {
        setviewData(false);
        setEdit("");
      } else {
        setviewData(true);
      }
    } else {
      setviewData(false);
    }
  }
  //Edit Button Clicked
  useEffect(() => {
    // console.log("Edited", edit);

    // GetTicketById.php
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Id", edit);
    axios({
      method: "post",
      url: Domain + "/GetTicketById.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Response for Edit:", response.data);
        if (response.data.success) {
          setTicketDetails(response.data.data);
          HandleView();
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log("Error:", response);
      });
  }, [edit]);

  //To get Developer list from database
  useEffect(() => {
    let formData = new FormData();

    formData.append("Usertype", "Developer");
    axios({
      method: "post",
      url: Domain + "/getall_users.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle successx
        console.log("Response:", response.data);
        setDevelopers(response.data.data);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  }, []);

  const DevelopersList = Developers.map((d) => ({
    value: d.id,
    label: d.FullName,
  }));
  const [DeveloperValues, setDevelopersValue] = React.useState({
    Id: null,
    label: "",
  });
  console.log("selected ", DeveloperValues);
  const [MaxDueDate, setMaxDueDate] = useState("");
  useEffect(() => {
    if (DeveloperValues.Id != null) {
      let formData = new FormData();
      formData.append("Type", 2);
      formData.append("DeveloperId", DeveloperValues.Id);
      axios({
        method: "post",
        url: Domain + "/GetMaxDueDateDev.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Response:", response.data);
          setMaxDueDate(response.data.data);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification;
        });
    }
  }, [DeveloperValues.Id]);
  const [importantFlag, setImportantFlag] = React.useState("Normal");
  function HandleAssign() {
    if (DeveloperValues.Id != null) {
      setError(false);
      let formData = new FormData();
      formData.append("Type", 2);
      formData.append("DeveloperId", DeveloperValues.Id);
      formData.append("DeveloperName", DeveloperValues.Label);
      formData.append("Id", TicketDetials.id);
      formData.append("Status", "Assigned");
      formData.append("Flag", importantFlag);
      formData.append("Hours", 0);
      formData.append("DueDate", startDate);
      axios({
        method: "post",
        url: Domain + "/Assign_toDeveloper.php",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          //handle success
          console.log("Response:", response.data);
          setMessage("Ticket Assigned successfully!");
          showSavedNotification();
          HandleView();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          showDeletedNotification;
        });
    } else {
      setError(true);
    }
  }
  // =======================================
  // console.log("checkbox: ", importantFlag);
  function HandleImportant() {
    if (importantFlag != "") {
      setImportantFlag("Normal");
    } else {
      setImportantFlag("Important");
    }
  }
  //Refresh Function
  const HandleRefresh = () => {
    if (!refresh) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  };
  // setMessage("Ticket Assigned successfully!")
  function HandleDeveloperFilter(developer, status) {
    let formData = new FormData();
    formData.append("Type", 2);

    formData.append("Status", status);
    formData.append("DeveloperId", developer);
    axios({
      method: "post",
      url: Domain + "/GetTicketsByStatusDev.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Pending Response:", response.data);
        if (response.data.TotalCount != 0) {
          if (response.data.success) {
            if (status == "Assigned") {
              setAssigned(response.data.data);
            } else if (status == "Working") {
              setWorking(response.data.data);
            } else if (status == "Completed") {
              setCompleted(response.data.data);
            } else if (status == "Pending") {
              setPending(response.data.data);
            }
          } else {
            setPendingEmpty(true);
          }
        }
      })
      .catch(function (response) {
        //handle error
        showDeletedNotification();
        console.log(response);
      });
  }
  return (
    <>
      <Snackbar
        place="tr"
        color="success"
        icon={DoneAllIcon}
        message={Message}
        open={saved}
        closeNotification={() => setSaved(false)}
        close
      />
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message="Error Please try again!"
        open={deleted}
        closeNotification={() => setDeleted(false)}
        close
      />
      <Dialog
        fullScreen
        open={viewData}
        onClose={HandleView}
        TransitionComponent={Transition}
      >
        <AppBar color="info" sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={HandleView}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <Card>
          <br />
          <GridContainer>
            <GridItem md={12} sm={12} lg={4}>
              {TicketDetials.Image == "" ? (
                <center>
                  <img
                    style={{
                      maxHeight: "200px",
                    }}
                    src={placeholder}
                    alt=""
                  />
                </center>
              ) : (
                <center>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Domain + "/images/" + TicketDetials.Image}
                  >
                    <Card sx={{ maxWidth: 345 }}>
                      <Box sx={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          height="400"
                          image={Domain + "/images/" + TicketDetials.Image}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            bgcolor: "rgba(0, 0, 0, 0.54)",
                            color: "white",
                            padding: "10px",
                          }}
                        >
                          <Typography variant="body2">View Image</Typography>
                        </Box>
                      </Box>
                    </Card>
                  </a>
                </center>
              )}
            </GridItem>
            <GridItem md={12} sm={12} lg={6}>
              Ticket Number : <Chip label={TicketDetials.id} color="info" />,
              <br />
              <table>
                <tr>
                  <td width="150px">Ticket Status </td>
                  <td>
                    : <b>{TicketDetials.Status}</b>
                  </td>
                </tr>

                <tr>
                  <td>Name </td>
                  <td>
                    : <b>{TicketDetials.FullName}</b>
                  </td>
                </tr>
                <tr>
                  <td>Subject </td>
                  <td>
                    : <b>{TicketDetials.Subject}</b>
                  </td>
                </tr>
                <tr>
                  <td>Project Name </td>
                  <td>
                    : <b>{TicketDetials.Project}</b>
                  </td>
                </tr>
                <tr>
                  <td>Issue Description </td>
                  <td>
                    : <b>{TicketDetials.Description}</b>
                  </td>
                </tr>
                <tr>
                  <td>Ticket Raised Date </td>
                  <td>
                    : <b>{TicketDetials.CreateDate}</b>
                  </td>
                </tr>
              </table>
              {/* Name : <b>{TicketDetials.FullName}</b>
              <br />
              Subject : <b>{TicketDetials.Subject}</b> <br />
              Ticket Number : <b>{TicketDetials.id}</b> <br />
              Ticket Status : <b>{TicketDetials.Status}</b> <br />
              Project Name : <b>{TicketDetials.Project}</b> <br />
              Issue Description : <b>{TicketDetials.Description}</b> <br />
              Ticket Raised Date : <b>{TicketDetials.CreateDate}</b> <br /> */}
              <GridItem xs={12} sm={12} md={6}>
                <SingleSelect
                  noOptionsMessage="Add any developer first"
                  placeholder="Select Developer"
                  Options={DevelopersList}
                  setValue={setDevelopersValue}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
                {MaxDueDate != "" && <b>Last due date:{MaxDueDate}</b>}
              </GridItem>
              <br />
              <GridItem xs={12} sm={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Due Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue.toLocaleDateString("en-CA"));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </GridItem>
              <br />
              <GridItem xs={12} sm={12} md={6}>
                <input
                  type="checkbox"
                  id="impflag"
                  name="impflag"
                  value="important"
                  onChange={HandleImportant}
                />
                Important Job
              </GridItem>
              {DevError ? (
                <>
                  <Danger>Please Select a Developer First</Danger>
                </>
              ) : (
                <></>
              )}
              <GridContainer className={classes.pullRight}>
                <Button round onClick={HandleView}>
                  Go back
                </Button>
                <Button color="info" round onClick={HandleAssign}>
                  Assign
                </Button>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <br />
        </Card>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button round onClick={HandleRefresh}>
            Refresh
          </Button>
          <NavPills
            color="info"
            tabs={[
              {
                tabButton: "Raised Tickets - " + Raised.length,
                tabContent: (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <CardHeader color="info">
                          <h4 className={classes.cardTitleWhite}>
                            All tickets Raised
                          </h4>
                          <p className={classes.cardCategoryWhite}>
                            All tickets Raised will be listed below
                          </p>
                        </CardHeader>
                        <CardBody>
                          <LoadingOverlay
                            active={false}
                            spinner
                            text="Please Wait.."
                          >
                            {RaisedEmpty ? (
                              <p>empty</p>
                            ) : (
                              <Table
                                tableHeaderColor="info"
                                tableHead={[
                                  "Status",
                                  "Id",
                                  "Create Date",
                                  "Due Date",

                                  "Project",
                                  "Subject",
                                  "Actions",
                                ]}
                                tableData={RaisedData}
                                noAction={false}
                                setEdit={setEdit}
                                Images={false}
                                loading={loading}
                              />
                            )}
                          </LoadingOverlay>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                ),
              },
              {
                tabButton: "Assigned Tickets - " + Assigned.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Current Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Currently working Tickets will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {AssignedEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Id",
                                    "Assigned To",
                                    "Create Date",
                                    "Due Date",

                                    "Project",
                                    "Subject",
                                    "Action",
                                  ]}
                                  tableData={AssignedData}
                                  noAction={false}
                                  setEdit={setEdit}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "Working Tickets - " + Working.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Working Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Completed Tickets will be listed below
                            </p>
                          </CardHeader>

                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {WorkingEmpty ? (
                                <p>empty</p>
                              ) : (
                                <>
                                  <Table
                                    tableHeaderColor="info"
                                    tableHead={[
                                      "Status",
                                      "Id",
                                      "Assigned To",
                                      "Create Date",
                                      "Due Date",

                                      "Project",
                                      "Subject",
                                    ]}
                                    tableData={WorkingData}
                                    noAction={true}
                                    setEdit={setEdit}
                                    Images={false}
                                    loading={loading}
                                  />
                                </>
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "Completed Tickets - " + Completed.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Completed Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Completed Tickets will be listed below
                            </p>
                          </CardHeader>
                          {Loading1 ? <LinearIndeterminate /> : <></>}
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {CompletedEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table1
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Assigned To",
                                    "Create Date",
                                    "Due Date",
                                    "Id",
                                    "Project",
                                    "Subject",
                                    "Verify",
                                  ]}
                                  tableData={CompletedData}
                                  noAction={false}
                                  setEdit={setVerify}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
              {
                tabButton: "Pending Tickets - " + Pending.length,
                tabContent: (
                  <span>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info">
                            <h4 className={classes.cardTitleWhite}>
                              List of Pending Tickets
                            </h4>
                            <p className={classes.cardCategoryWhite}>
                              Pending Tickets will be listed below
                            </p>
                          </CardHeader>
                          <CardBody>
                            <LoadingOverlay
                              active={false}
                              spinner
                              text="Please Wait.."
                            >
                              {PendingEmpty ? (
                                <p>empty</p>
                              ) : (
                                <Table
                                  tableHeaderColor="info"
                                  tableHead={[
                                    "Status",
                                    "Id",
                                    "Assigned To",
                                    "Create Date",
                                    "Due Date",

                                    "Project",
                                    "Subject",
                                  ]}
                                  tableData={PendingData}
                                  noAction={true}
                                  setEdit={setEdit}
                                  Images={false}
                                  loading={loading}
                                />
                              )}
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </span>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dashboard from "@material-ui/icons/Dashboard";
// core components
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import Notifications from "@material-ui/icons/Notifications";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { Domain } from "Domain";
import { useState } from "react";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks({ setLoggedin }) {
  const UserDetails = JSON.parse(window.localStorage.getItem("userdetails"));
  const [NotificationsData, setNotifications] = useState([]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("DeveloperId", UserDetails.id);
    formData.append("Status", "unread");
    formData.append("Team", UserDetails.TeamId);

    axios({
      method: "post",
      url: Domain + "/GetNotificationsByStatus.php",
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        //handle success
        console.log("Notifications:", response);
        if (response.data.success) {
          setNotifications(response.data.data);
        }
      })
      .catch(function (response) {
        //handle error

        console.log(response);
      });
  }, []);

  const [openNotification, setOpenNotification] = React.useState(null);

  console.log("setLoggedIn,navlinks", setLoggedin);
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = () => {
    setLoggedin(undefined);
    setOpenProfile(null);
  };
  return (
    <div>
      <NavLink to="/admin" className={classes.item}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Dashboard"
          className={classes.buttonLink}
        >
          <Dashboard className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Dashboard</p>
          </Hidden>
        </Button>
      </NavLink>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {NotificationsData.length != 0 ? (
            <>
              <span className={classes.notifications}>
                {NotificationsData.length}
              </span>
            </>
          ) : (
            <></>
          )}

          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {NotificationsData != "" ? (
                      NotificationsData.map((notification) => {
                        return (
                          <>
                            <MenuItem
                              key={notification.Id}
                              onClick={handleCloseNotification}
                              className={classes.dropdownItem}
                            >
                              <b>
                                New {notification.Project} {notification.Type}{" "}
                                Ticket Assigned
                              </b>{" "}
                              <hr size="1" color="#D3D3D3" />
                            </MenuItem>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    <center>
                      <Button
                        size="sm"
                        simple
                        color="info"
                        onClick={() =>
                          alert("ഇവിടെ ഒന്നും ഇല്ല പോയി ബാക്കി പണി നോക്ക്")
                        }
                      >
                        view all
                      </Button>
                    </center>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* <MenuItem onClick={handleCloseProfile}>
                      {" "}
                      <NavLink to="/user">Profile</NavLink>
                    </MenuItem> */}
                    {/* <Divider light /> */}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  setLoggedin: PropTypes.any,
};
